.contact-image-container{
    display: flex;
     justify-content: center ;
     align-items: center;
      border: 2px solid #000000;
       height: 420px;
        width: 420px; 
}

.contact-image{
    height: 400px;
    width: 400px;
}

@media only screen and (max-width: 753px) {
    .contact-image-container{
        display: flex;
         justify-content: center ;
         align-items: center;
          border: 2px solid #000000;
           height: 420px;
            width: 420px; 
            margin-top: -40px;
            margin-bottom: 10px;
    }
    
    .contact-image{
        height: 400px;
        width: 400px;
    }
   
  }

@media only screen and (max-width: 470px) {
    
    .contact-image-container{
        display: flex;
         justify-content: center ;
         align-items: center;
          border: 2px solid #000000;
           height: 320px;
            width: 300px; 
            margin-bottom: 30px;
            margin-top: -10px;
    }
    
    .contact-image{
        height: 300px;
        width: 280px;
    }
  }

 