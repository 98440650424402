

.home-main{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px
}
.home-container{
    height: 600px;
    width: 850px;
    border: 2px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px
  }
  .home-corousal{
      width: 800px;
  }
.home-heading-1{
    margin-right: 100px;
      margin-left: 100px;

}
  .home-para{
      margin-right: 100px;
      margin-left: 100px;
  }

  @media only screen and (max-width: 850px) {
    
    .home-container{
        width: 450px;
        height: 300px;
    }

    .home-corousal{
        width: 400px;
    }   
  }

  @media only screen and (max-width: 460px) {
    
    .home-container{
        width: 300px;
        height: 200px;
    }

    .home-corousal{
        width: 250px;
    }   
  }

  @media only screen and (max-width: 642px) {
    .home-heading-1{
    margin-right: 30px;
      margin-left: 30px;
      
}
    .home-para{
        margin-right: 30px;
        margin-left: 30px;
    }
  }



  @media only screen and (max-width: 438px) {
    .home-heading-1{
    margin-right: 30px;
      margin-left: 30px;
      font-size: 1.3rem;
      font-weight: bold;
    }
    
    .home-para{
        margin-right: 30px;
        margin-left: 30px;
        font-size: 1rem;
        font-weight: normal;
    }
  }